import './App.css';
import Luck from "./component/Luck";
import Light from "./component/Light";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import WatchVideo from "./component/WatchVideo";
import CheckLight from "./component/CheckLight";
import CheckNotFound from "./component/CheckNotFound";
import Pray from "./component/Pray";

function App() {
  return (
      // <BrowserRouter>
      //     <Routes>
      //         <Route path="/" element={<Light />} />  {/* 定义根路径路由 */}
      //         <Route path="/light" element={<Light />} />
      //         <Route path="/watchLightVideo" element={<WatchVideo />} />
      //         <Route path="/checkLight" element={<CheckLight />} />
      //         <Route path="/checkNotFound" element={<CheckNotFound />} />
      //     </Routes>
      // </BrowserRouter>
    <Light />
    // <Luck/>
    // <Pray/>


  );
}

export default App;
