import {PureComponent} from "react";
import '@nutui/nutui-react/dist/style.css'


import './index.css';
import '../CheckLight/index.css'
import '../CheckNotFound/index.css'
import {Card} from "antd-mobile";

import {Button, DatePicker, Form, Input, Overlay, Radio, TextArea, Toast} from '@nutui/nutui-react'
import Icp from "../Icp";

const wrapperStyle = {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
}
const contentStyle = {
    display: 'flex',
    width: '100%',
    height: '100%',
    background: '#fff',
    borderRadius: '8px',
    alignItems: 'center',
    justifyContent: 'center',
}

class Light extends PureComponent {

    state = {
        showForm: false,
        dateRef: null,
        datePickerVisible: false,
        passCode: "",
        birthdate: "",
        birthdateDisplay: "",
        name: "",
        gender: "",
        isDataOK: false,
        productData: {},
        productID: "",
        showVideo: false,
        displayVideo:"",
    };


    setOkFalse() {
        if (this.state.isDataOK) {
            this.setState({isDataOK: false});
        }
    }

    onNameChange = (v) => {
        this.setState({name: v});
        this.checkData();
    }

    onPassCodeChange = (v) => {
        this.setState({passCode: v});
        this.checkData();
    }
    onGenderChange = (v) => {

        this.setState({gender: v});
        this.checkData();
        // console.log(this.state)
    }

    activeOwner = () => {
        // console.log(window.reactPress.user.data);
        // if(Object.keys(window.reactPress.user.data).length===0){
        //
        //     if(!window.location.toString().includes("?id")){
        //         window.location=`${window.location}?id=${this.state.productID}`;
        //     }
        //
        //     window.open(`https://wtwt.cn/wp-login.php`);
        //     window.location.reload();
        //
        //
        // }else{
        //     this.setState({showForm:true})
        // }
        this.setState({showForm: true})

        /*
        1.if(login) show active form
        else show login

         */

    }
    watchLightVideo = (url, title) => {

        // window.open(`/watchLightVideo?url=${encodeURIComponent(url)}&&title=开光视频`);
        this.setState({showVideo: true,displayVideo:url})
    }

    checkData = () => {
        // console.log(this.state);

        setTimeout(() => {
            if (this.state.passCode === "") {
                this.setOkFalse();
                return;
            }
            if (this.state.birthdate === "") {
                this.setOkFalse();
                return;
            }
            if (this.state.name === "") {
                this.setOkFalse();
                return;
            }
            if (this.state.gender === "") {
                this.setOkFalse();
                return;
            }

            this.setState({
                isDataOK: true
            });

        }, 100);

    }

    submitSucceed = (values) => {
        // console.log(values);

        if (!this.state.isDataOK) return;

        // if (values === undefined
        //     // || birthdate === DATE_PICKER_DEFAULT
        //     // || Object.keys(values).length !== 2
        // ) {
        //     Toast.show({content: "\"请输入个人信息\"", icon: 'fail'})
        //     return;
        // }

        const userName = values.userName.toString();
        var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
        if (!reg.test(userName)) {
            Toast.show({content: "\"请输入正确的姓名\"", icon: 'fail'})
            return;
        }

        // if (this.state.birthdate === "") {
        //     Toast.show({content: "\"请输入生日\"", icon: 'fail'})
        //     return;
        // }
        //
        //
        // const gender = values.gender;
        // if (gender === undefined) {
        //     Toast.show({content: "\"请选择性别\"", icon: 'fail'})
        //     return;
        // }


        const params = new URLSearchParams(window.location.search);
        const productID = params.get('id');
        // '{"serial_no":"sdfdffdfas990000000","user_name":"asstest","gender":"男","birth_time":"1999/02/11T10:40"}'
        const param = {
            user_name: values.userName.toString(),
            gender: values.gender.toString() === "male" ? "男" : "女",
            birth_time: this.state.birthdate,
            pass_code: this.state.passCode,
            serial_no: productID.toString()//"2024-08-29T01:47"
        }
        // console.log(param);

        // const that = this;
        try {
            // request
            fetch('https://wtwt.cn/wp-json/myapi/v1/openlight/update-owner', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify(param)
            })
                .then(response => response.json())
                .then(data => {


                    if (data.hasOwnProperty("code")) {
                        // console.log("chen1:" + JSON.stringify(data))
                        Toast.show({content: "\"PassCode\"错误", icon: 'success'})
                    } else {
                        Toast.show({content: "\"修改成功\"", icon: 'success'})
                        // console.log("chen2:" + JSON.stringify(data))
                        window.location.reload();
                    }
                    // if(data.code==="passcode_incorrect"){
                    //
                    // }

                    // this.parseData(data, that);
                    // console.log("chen:" + JSON.stringify(data))
                });
        } catch (error) {
            console.error(error.message);
        }
    }


    showDatePicker = () => {
        // console.log("chen:" + this.state.datePickerVisible);
        this.setState({datePickerVisible: true});

    }

    hideDatePicker = () => {
        this.setState({datePickerVisible: false});

    }

    componentDidMount() {

        // console.log(this.props);
        const params = new URLSearchParams(window.location.search);
        const productID = params.get('id');
        document.title = "祈福加持法物核验";

        if (productID !== null) {
            this.setState({productID})
            try {
                // request
                fetch('https://wtwt.cn/wp-json/myapi/v1/openlight/serial-no/' + productID, {
                    method: 'GET', headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        this.setState({productData: data});
                        // console.log("chen:" + JSON.stringify(data))

                    });
            } catch (error) {
                console.error(error.message);
            }

        }

    }

    render() {
        const productData = this.state.productData;
        // console.log("chen");
        // console.log(productData);

        const params = new URLSearchParams(window.location.search);
        const pID = params.get('id');
        if (pID === null) {
            if (Object.keys(productData).length === 0) {
                return this.renderCheck();
            }
        }


        if (Object.keys(productData).length === 3) {
            return this.renderNotFound();
        }

        let hasOwner = false;
        if (productData !== undefined && productData.owner !== null) {
            hasOwner = true;
        }




        let product_image = "", product_name = "", owner = "", light_video = "", open_light_date = "",fuVideo="",
            display_name = "";
        let showFuVideo=false;
        if (productData != null) {
            product_image = productData.product_image;
            product_name = productData.product_name;
            // product_name="财运子母灵符御守 cstbgkg0088";
            owner = productData.owner;
            light_video = productData.light_video;
            open_light_date = productData.open_light_date;
            // serial_no = productData.serial_no;
            display_name = productData.display_name;
            fuVideo = productData.fuVideo;

            if(fuVideo!==null&&fuVideo!==undefined&&fuVideo.length>0){
                showFuVideo=true;
            }

        }


        return (

            <div className="light-container">
                <div className="title1">祈福仪式</div>

                {this.state.showForm && this.renderForm()}
                <div className="chinese-style-frame-outer">
                    <div className="chinese-style-frame">
                        <div className="light-row_layout">
                            <div className="circled-text"> 祈
                            </div>
                            <div className="v-line"></div>
                            <div className="circled-text"> 福</div>
                            <div className="v-line"></div>
                            <div className="circled-text"> 仪</div>
                            <div className="v-line"></div>
                            <div className="circled-text"> 式</div>
                        </div>
                        <div className="light-title2">恭喜您，该法宝经过祈福仪式加持！</div>

                        <img className="circled-img"
                             src={product_image}
                             alt="img"/>

                        {hasOwner && <>
                            <div className="dash-line"/>
                            <div className="info">藏品主人：{owner}</div>
                        </>}

                        <div className="dash-line"/>
                        <div className="light-info">法宝名称：{product_name}</div>
                        <div className="dash-line"/>
                        <div className="info">仪式时间：{open_light_date}</div>
                        <div className="dash-line"/>
                        <div className="info">产品编号：{display_name}</div>


                        {!hasOwner && <div className="active-btn" onClick={this.activeOwner}>激活认主</div>}


                        <div className="light-btn-container">
                            {hasOwner&&this.renderVideo(light_video,product_name)}
                            {(hasOwner&&showFuVideo)&&this.renderFuVideo(fuVideo)}

                        </div>

                        <Overlay visible={this.state.showVideo} onClick={() => this.setState({showVideo: false})}>
                            <div style={wrapperStyle}>
                                <div className="light-video-container">
                                    <video style={contentStyle} controls={true} autoPlay muted={true} loop={true}>
                                        <source src={this.state.displayVideo} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </Overlay>

                    </div>


                </div>

            </div>

        );

    }

    renderVideo(light_video,product_name){
        return <div className="light-active-btn"
                    onClick={() => this.watchLightVideo(light_video, product_name)}>观看仪式视频</div>
    }

    renderFuVideo(v){
        return <div className="light-active-btn"
                    onClick={() => this.watchLightVideo(v, "")}>母符挂殿视频</div>
    }



    renderForm() {
        return <div style={{visibility: this.state.showForm ? "visible" : "hidden"}}>
            <text className="title">激活认主需补充</text>
            <div className="font_title_1">请填写八字信息</div>
            {this.wrapperCard(
                <div>

                    <Form
                        name="login"
                        style={{backgroundColor: "transparent"}}
                        footer={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: "center",
                                    width: '100%',
                                }}
                            >
                                <Button disabled={!this.state.isDataOK} style={{
                                    width: "100%",
                                    color: "white",
                                    backgroundColor: this.state.isDataOK ? "rgba(179, 38, 30, 1)" : "#CCCCCC"
                                }}
                                        className={"submitButton"}
                                        nativeType={this.state.isDataOK ? "submit" : ""}>
                                    立刻激活
                                </Button>

                            </div>
                        }
                        // onSubmit={(values) => this.submitSucceed(values)}
                        onFinish={(values) => this.submitSucceed(values)}
                        // onFinishFailed={(values, errors) => this.submitSucceed(values)}
                    >
                        <Form.Item label="PassCode：" name="passCode"
                                   className="nut-cell-title"
                                   align="center"
                        >
                            <Input className="input-area" placeholder="请输入PassCode"
                                   onChange={this.onPassCodeChange}/>
                        </Form.Item>

                        <Form.Item label="姓名：" name="userName"
                                   className="nut-cell-title"
                                   align="center"
                        >
                            <Input className="input-area" placeholder="请输入姓名" onChange={this.onNameChange}/>
                        </Form.Item>


                        <Form.Item label="性别：" name="gender"

                                   align="center"
                                   initialValue={this.state.gender}
                        >
                            <Radio.Group onChange={this.onGenderChange}>
                                <Radio value="male">男</Radio>
                                <Radio value="female">女</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="生日：" name="birthdate"
                                   className="navigator-arrow"

                                   align="center"
                                   initialValue=""

                        >
                            <div className={"input-area"}
                                 onClick={() => this.showDatePicker()}
                                 readOnly
                            >
                                {this.state.birthdate === "" ? "选择出生时间" : this.state.birthdateDisplay}
                            </div>


                        </Form.Item>

                    </Form>
                    <DatePicker
                        title="选择出生日期"
                        visible={this.state.datePickerVisible}
                        type="datetime"
                        startDate={new Date(1900, 1, 1)}
                        endDate={new Date()}
                        defaultValue={new Date()}
                        onClose={() => this.hideDatePicker()}
                        onConfirm={(options, values) => this.datePickConfirm(values, options, this)}
                    />


                </div>
                , 355, 20, "rgba(255, 255, 255, 1)")}
        </div>;
    }

    wrapperCard(child, width = 300, marginTop = 48, backgroundColor = "rgba(255, 255, 255, 0.6)") {
        return <div
            style={{
                marginTop: marginTop, background: "transparent", justifyContent: "center", marginBottom: 20
            }}
        >
            <div
                style={{
                    display: "flex", background: "transparent", alignItems: "center", justifyContent: "center", flex: 1
                }}
            >
                <Card title="" bordered={false} style={{background: backgroundColor, width: width}}>
                    {child}
                </Card>
            </div>
        </div>;
    }

    datePickConfirm = async (values, options, that) => {
        // console.log("chen" + JSON.stringify(values))
        const date = values.slice(0, 3).join('-')
        const time = values.slice(3).join(':')
        const dd = `${date}T${time}`;
        const birthdateDisplay = `${values[0]}年${values[1]}月${values[2]}日${values[3]}点${values[3]}分`;
        this.setState({birthdate: dd, birthdateDisplay});


        setTimeout(() => {
            this.checkData();
            // console.log(dd);
            // console.log(this.state.birthdate);
        }, 20);


    }

    checkId() {

        // console.log(this.state.productID);
        if (this.state.productID == null || this.state.productID.length === 0) {
            // Toast.show({content: "\"请输入正确的编号\"", icon: 'fail'})
            return;
        }


        try {
            // request
            fetch('https://wtwt.cn/wp-json/myapi/v1/openlight/serial-no/' + this.state.productID, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    // this.parseData(data, that);
                    this.setState({productData: data})
                    if (data.hasOwnProperty("code")) {

                    }else{
                        window.location = `${window.location}?id=${this.state.productID}`;
                    }

                });
        } catch (error) {
            console.error(error.message);
        }

    }

    parseData(data, that) {
        // console.log(data);
        // console.log(data.code);
        // console.log("parse data");
        // console.log(productData)

        this.setState({productData: data})
        if (Object.keys(data).length === 3) {


            setTimeout(() => {
                // window.open("/light")
                // this.props.history.push("/light");
                // window.location.href = '/light';
                // navigate(`/light?id=${productID}`, { replace: false });
            }, 1000);

        } else {
            // navigate('/checkNotFound', { replace: false });
        }
    }


    renderCheck() {
        return <div className="check-container">
            <div className="check-title">祈福加持法物核验</div>
            <div className="check-card-container">
                <TextArea value={this.state.productID} style={{fontSize: 16, color: "black", flex: 1, padding: 4}}
                          placeholder="请输入产品ID" onChange={(v) => {
                    this.setState({productID: v})
                }
                }/>

                <div className="space"></div>
                <div className="check-light-btn" onClick={() => this.checkId()}>立即查询</div>
            </div>
            <Icp/>
        </div>
    }

    renderNotFound() {
        return <div className="check-notfound-container">
            <div className="notfound-container">
                <div className="img-notfound"></div>
                <div className="text-notfound">对不起，该产品没有找到加持记录</div>

                <div className="check-btn" onClick={() => {

                    this.setState( {
                        showForm: false,
                            dateRef: null,
                            datePickerVisible: false,
                            passCode: "",
                            birthdate: "",
                            birthdateDisplay: "",
                            name: "",
                            gender: "",
                            isDataOK: false,
                            productData: {},
                            productID: "",
                            showVideo: false,
                    });
                }
                }>返回
                </div>
            </div>

        </div>
    }


}

export default Light;
